body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  background: #000;
  color: #ffffff;
  overflow-x: hidden;
}
a {
  color: #62c2bd;
  text-decoration: none;
}
a:hover {
  color: #62c2bd;
  text-decoration: underline;
}
/* Custom Scrollbar */
*::-webkit-scrollbar {
  width: 6px;
  height: 4px;
}
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
}
*::-webkit-scrollbar-thumb {
  background-color: #62c2bd;
}
img.brand_logo {
  width: 240px;
}
.navbar-sticky--moved-up {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #000;
  margin-top: -100px;
  -webkit-box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
}
.navbar-sticky--on {
  margin-top: 0;
}
.navbar-sticky--transitioned {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.main_navbar {
  z-index: 10;
  padding: 15px 0;
  backdrop-filter: blur(25px);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 1%,
    rgba(0, 0, 0, 0.25) 50%,
    rgba(0, 0, 0, 0) 100%
  );
}
.main_navbar .primary_btn span {
  background: radial-gradient(
    120% 180% at -15% 33%,
    rgb(98, 194, 189),
    rgb(0, 0, 0) 54%,
    rgb(46, 92, 90) 100%
  );
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.main_navbar .navbar-brand {
  display: flex;
}
.navbar_right {
  display: flex;
  align-items: center;
  gap: 12px;
}
.navbar_right a {
  text-decoration: none !important;
}
.address_btn span {
  width: 180px !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  display: block;
}
.dropdown-toggle.primary_btn::after {
  position: absolute;
  right: 15px;
}
.dropdown-menu {
  background: linear-gradient(180deg, rgb(0, 0, 0) 50%, rgb(98, 194, 189) 375.495%);
  mix-blend-mode: plus-lighter;
  padding: 0;
  overflow: hidden;
}
.dropdown-menu li a {
  color: #fff;
  font-size: 15px;
  text-decoration: none !important;
}
.dropdown-menu li a:hover {
  background: radial-gradient(
    303% 92% at 4% 11%,
    rgb(98, 194, 189),
    rgb(0, 0, 0) 54%,
    rgb(46, 92, 90) 100%
  );
  color: #fff;
}
.page_header {
  min-height: 100vh;
  margin-top: -82px;
  position: relative;
  z-index: 1;
  padding: 70px 0;
}
.page_header::before {
  content: "";
  background: url("./assets/images/banner_bg.png") no-repeat center;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 0;
  z-index: -1;
}
.banner_content {
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  gap: 15px;
}
.banner_content img {
  margin: -150px 0 -50px;
  width: 650px;
}
.banner_content h5 {
  font-size: 48px;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: -1.04px;
  margin-bottom: 0;
}
.banner_content h2 {
  color: #ffffff;
  font-size: 48px;
  font-weight: 700;
  line-height: 66px;
  margin-bottom: 0;
}
.banner_content p {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 0;
}
.banner_content p span {
  font-weight: 800;
}
.primary_btn {
  align-items: center;
  background: linear-gradient(
    90deg,
    #62c2bdff 0%,
    #ffffffff 50%,
    #2e5c5aff 100%
  );
  border: 0;
  backdrop-filter: blur(2.5px);
  border-radius: 30px;
  box-sizing: border-box;
  color: #ffffff !important;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.72px;
  justify-content: center;
  line-height: normal;
  max-width: 100%;
  min-width: 140px;
  padding: 1px;
  text-decoration: none;
  user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s;
  text-decoration: none !important;
  text-align: center;
}
.primary_btn:active,
.primary_btn:hover {
  outline: 0;
}
.primary_btn span {
  background: radial-gradient(
    120% 180% at -15% 33%,
    rgb(98, 194, 189),
    rgb(0, 0, 0) 54%,
    rgb(46, 92, 90) 100%
  );
  padding: 12px 24px;
  border-radius: 30px;
  width: 100%;
  height: 100%;
  transition: 300ms;
}
.primary_btn:hover span {
  background: radial-gradient(
    120% 180% at -15% 33%,
    rgb(46, 92, 90),
    rgb(0, 0, 0) 54%,
    rgb(98, 194, 189) 100%
  );
}
.primary_btn:active {
  transform: scale(0.9);
}
section {
  padding: 50px 0;
  position: relative;
  z-index: 1;
}
.main_title {
  font-size: 40px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1.5px;
  margin-bottom: 40px;
  text-transform: capitalize;
}
.small_main_title {
  font-size: 40px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -1.6px;
}
.para {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}
.about_section h5 {
  color: #ff0008;
  font-size: 20px;
  font-style: italic;
  font-weight: 700;
  line-height: 32px;
  margin-top: 40px;
}
.about_section {
  background: #050909;
}
.community_section_inner {
  position: relative;
  height: 100%;
}
.community_section_inner .nav-pills {
  position: absolute;
  bottom: 0;
  width: 100%;
  justify-content: space-between;
}
.community_section_inner .tab-content {
  max-width: 40%;
  margin-left: auto;
}
.community_section_inner .nav-fill .nav-item {
  flex: unset;
}
.community_section_inner .nav-fill .nav-item .nav-link {
  border-radius: 100px;
  background: #000;
  padding: 15px 30px 15px 15px;
  color: #616161;
  font-size: 40px;
  font-weight: 600;
  letter-spacing: -1.6px;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}
.community_section_inner .nav-fill .nav-item .nav-link.active,
.community_section_inner .nav-fill .nav-item .nav-link:hover {
  background: #0056ff;
  color: #fff;
}
.community_section_inner .nav-fill .nav-item .nav-link.active svg circle,
.community_section_inner .nav-fill .nav-item .nav-link:hover svg circle {
  fill: #fff;
}
.button__icon-wrapper {
  flex-shrink: 0;
  width: 60px;
  height: 60px;
  position: relative;
  color: #000;
  background-color: #fff;
  border-radius: 50%;
  display: grid;
  place-items: center;
  overflow: hidden;
}
.community_section_inner .nav-fill .nav-item:hover .button__icon-wrapper {
  color: #000;
}

.button__icon-svg--copy {
  position: absolute;
  transform: translate(-150%, 150%);
}

.community_section_inner
  .nav-fill
  .nav-item
  .nav-link:hover
  .button__icon-svg:first-child {
  transition: transform 0.3s ease-in-out;
  transform: translate(150%, -150%);
}

.community_section_inner
  .nav-fill
  .nav-item
  .nav-link:hover
  .button__icon-svg--copy {
  transition: transform 0.3s ease-in-out 0.1s;
  transform: translate(0);
  color: #0056ff;
}
.community_section_inner .nav-fill .nav-item .nav-link:hover {
  background: #0056ff;
}

.activity_section {
  padding: 100px 0;
}
.activity_section .main_title {
  color: #ff0008;
  text-align: center;
  margin-bottom: 50px;
}
.activity_section .small_main_title {
  text-align: center;
  margin-top: 50px;
}
.activity_slider_single {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 100px;
  background: rgba(14, 14, 14, 0.5);
  border: 3px solid rgba(14, 14, 14, 0.5);
  padding: 20px;
  margin: 10px;
}
.activity_section
  .slick-active.slick-center.slick-current
  .activity_slider_single {
  border: 3px solid #0056ff;
}
.activity_details {
  display: flex;
  align-items: center;
  gap: 20px;
}
.activity_details img {
  width: 62px;
}
.activity_details p {
  color: #0056ff;
  margin-bottom: 0;
}
.activity_details h5 {
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.96px;
  margin-bottom: 0;
}
.activity_time {
  display: flex;
  align-items: center;
  gap: 10px;
}
.activity_time p {
  color: #616161;
  margin-bottom: 0;
}
.activity_section .slick-slider {
  margin: 50px 0 120px;
}
.activity_section .slick-active.slick-center.slick-current .activity_details p {
  color: #fff;
}
.activity_section .slick-active.slick-center.slick-current .activity_time p {
  color: #0056ff;
}
.activity_section
  .slick-active.slick-center.slick-current
  .activity_time
  svg
  path {
  fill: #0056ff;
}
.activity_details > div {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.custom_slider .slick-prev,
.custom_slider .slick-next {
  background: #0a0f0f !important;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  z-index: 1;
  display: flex !important;
  align-items: center;
  justify-content: center;
  top: unset;
  bottom: -80px;
}
.custom_slider .slick-next::before,
.custom_slider .slick-prev::before {
  content: "";
  width: 29px;
  height: 22px;
  filter: brightness(0) invert(0.3);
}
.custom_slider .slick-prev::before {
  background: url("./assets/images/prev_arrow.png") no-repeat;
}
.custom_slider .slick-next::before {
  background: url("./assets/images/next_arrow.png") no-repeat;
}
.custom_slider .slick-prev:hover::before,
.custom_slider .slick-next:hover::before {
  filter: unset;
}
.custom_slider .slick-prev {
  left: 44%;
}
.custom_slider .slick-next {
  right: 44%;
}
.faq_section {
  padding: 100px 0;
}
.custom_accordian .accordion-item {
  background: transparent;
  border: none;
  border-bottom: 1px solid rgba(17, 17, 17, 0.8) !important;
}
.custom_accordian .accordion-button {
  background: transparent;
  border: none;
  box-shadow: none;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
}
.custom_accordian .accordion-body p.para {
  color: #fff;
  font-size: 16px;
  margin-bottom: 0;
  line-height: 24px;
  font-weight: 400;
}
.custom_accordian .accordion-button:not(.collapsed) {
  color: #9dfffa;
}
.custom_accordian .accordion-button::after {
  background: url("./assets/images/accordian_btn.png") no-repeat;
  width: 16px;
  height: 14px;
}
.custom_accordian .accordion-button:not(.collapsed)::after {
  background: url("./assets/images/accordian_btn.png") no-repeat;
  transform: rotate(90deg);
}
.footer_about p {
  font-size: 15px;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 0;
  max-width: 70%;
}
.footer_about img {
  width: 240px;
}
.footer_panel_top_center p {
  color: #9db9b7;
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 0;
}
.footer_panel_top_center a {
  color: #9dfffa;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  white-space: normal;
  word-break: break-all;
}
.benefits_list {
  padding: 0;
  margin: 0;
}
.benefits_list li {
  list-style: none;
  position: relative;
  font-size: 16px;
  font-weight: 400;
  line-height: auto;
  margin-top: 40px;
  padding-left: 50px;
}
.benefits_list li::after {
  content: "";
  background: url("./assets/images/list_icon.png") no-repeat;
  width: 28px;
  height: 27px;
  position: absolute;
  top: -2px;
  left: 0;
}
.footer {
  background: #050909;
  padding: 30px 0;
  z-index: 10;
  position: relative;
}
.footer_panel_top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #121b1a;
  margin-bottom: 15px;
  padding-bottom: 20px;
}
.footer ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
ul.footer_social_links {
  display: flex;
  gap: 20px;
}
ul.footer_social_links li a {
  background: #121b1a;
  color: #9db9b7;
  width: 36px;
  height: 36px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s all;
  text-decoration: none;
}
ul.footer_social_links li a:hover {
  background: #9dfffa;
  color: #000;
}
.footer_panel_bottom {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.footer_panel_bottom p {
  color: #9db9b7;
  font-size: 15px;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 0;
}
ul.footer_menu_links {
  display: flex;
  gap: 30px;
}
ul.footer_menu_links li {
  position: relative;
}
/* ul.footer_menu_links li::after {
  content: "";
  background: #fff;
  width: 1px;
  height: 10px;
  position: absolute;
  top: 10px;
  right: -15px;
} */
ul.footer_menu_links li:last-child:after {
  display: none;
}
ul.footer_menu_links li a {
  color: #9db9b7;
  font-size: 15px;
  font-weight: 400;
  line-height: 28px;
  text-decoration: none;
}
.stats_panel_wrapper {
  background: linear-gradient(
    180deg,
    rgb(0, 0, 0) 50%,
    rgb(98, 194, 189) 375.495%
  );
  padding: 15px 10px;
  z-index: 2;
  position: relative;
}
.stats_panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
}
.stats_panel div {
  display: flex;
  align-items: center;
  gap: 15px;
}
.stats_panel div label {
  white-space: nowrap;
}
.stats_panel div label:nth-child(1) {
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.64px;
}
.stats_panel div label:nth-child(2) {
  color: #62c2bd;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.72px;
}
.primary_modal .modal-content {
  background: linear-gradient(
    139.04deg,
    rgb(10, 15, 15) 3.47%,
    rgb(14, 45, 43) 134.161%
  );
}
.primary_modal .modal-header {
  border-bottom: 1px solid rgba(157, 255, 250, 0.2);
}
.primary_modal .modal-header .btn-close {
  filter: invert(1);
  box-shadow: none !important;
}
.connect_wallet_button {
  text-align: center;
  border: 1px solid rgba(157, 255, 250, 0.2);
  border-radius: 10px;
  padding: 15px;
  cursor: pointer;
  margin-bottom: 15px;
  transition: 0.2s all;
}
.connect_wallet_button:hover {
  border: 1px solid rgba(157, 255, 250, 0.8);
}
.connect_wallet_button img {
  width: 42px;
  margin-bottom: 10px;
}
.connect_wallet_button h4 {
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  margin-bottom: 0;
}
.connect_wallet_button p {
  margin-bottom: 0;
  color: #c5c5c5;
}
/* Dashboard Css */
.inner_wrapper {
  min-height: 100vh;
  padding-top: 50px;
  padding-bottom: 100px;
  position: relative;
  z-index: 1;
}
.inner_wrapper::before {
  content: "";
  background: url("./assets/images/logo_bg_01.png") no-repeat right top;
  width: 100%;
  height: 1100px;
  position: absolute;
  z-index: -1;
  top: -100px;
  right: 0;
}
.inner_wrapper::after {
  content: "";
  background: url("./assets/images/logo_bg_02.png") no-repeat left bottom;
  width: 100%;
  height: 599px;
  position: absolute;
  z-index: -1;
  bottom: -150px;
  left: 0;
}
.inner_head_title {
  font-size: 32px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -1.6px;
  margin-bottom: 100px;
}
.user_info_panel {
  display: flex;
  align-items: center;
  gap: 24px;
}
.inp_grp {
  display: flex;
  align-items: center;
  position: relative;
}
ul.share_list {
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  gap: 20px;
  background: rgba(46, 92, 90, 0.2);
  border-radius: 5px;
  padding: 8px 12px;
  margin-top: 5px;
}
.affiliate_icon {
  cursor: pointer;
}
.affiliate_inp {
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  background: transparent !important;
  border: none;
  box-shadow: none !important;
  padding-left: 0;
  user-select: none;
  pointer-events: none;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.user_info {
  width: 100%;
}
.user_info h4 {
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0;
}
.user_info h5 {
  color: #9f9f9f;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
}
.user_info h5 span {
  color: #fff;
}
.user_earnings_panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  padding: 20px;
  border-radius: 5px;
  background: #000;
}
.user_earnings_info {
  border-radius: 2.5px;
  background: rgb(37 37 37 / 25%);
  padding: 20px;
  mix-blend-mode: plus-lighter;
  text-align: center;
}
.user_earnings_info h4 {
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
}
.user_earnings_info h3 {
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0;
}
.user_earnings_info h3 span {
  color: #9f9f9f;
}
.user_earnings_info h3 small {
  color: #9f9f9f;
  font-size: 14px;
  font-weight: 500;
}
.dashboard_accordian {
  padding-left: 80px;
  margin-top: 50px;
}
.dash_height_row {
  position: relative;
  z-index: 1;
}
.dashboard_accordian .accordion-item {
  background: transparent;
  border: none;
  position: relative;
  margin-bottom: 100px;
}
.dashboard_accordian .accordion-item:last-child {
  margin-bottom: 0;
}
.dashboard_accordian .collapse.show::before {
  content: "";
  border-left: 1.5px dashed #1e1e1e;
  width: 1.5px;
  height: 100%;
  position: absolute;
  left: -4px;
  top: 30px;
  z-index: -1;
}
.dashboard_accordian .collapse.show::after {
  content: "";
  background: #1e1e1e;
  width: 9px;
  height: 9px;
  position: absolute;
  bottom: -30px;
  left: -8px;
  border-radius: 100%;
}
.dashboard_accordian .accordion-button {
  background: transparent;
  border: none;
  box-shadow: none;
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  gap: 10px;
}
.dashboard_accordian .accordion-button:not(.collapsed) {
  color: #0056ff;
}
.dashboard_accordian .accordion-button::after {
  position: absolute;
  left: -15px;
  background: url("./assets/images/plus_icon.png") no-repeat;
  width: 23px;
  height: 23px;
}
.accordion-button:not(.collapsed)::after {
  background: url("./assets/images/minus_icon.png") no-repeat;
}
.dash_top_box {
  border-radius: 20px;
  /* backdrop-filter: blur(50px); */
  background: rgba(46, 92, 90, 0.1);
  border: 1px solid #62c2bd;
  padding: 20px;
  z-index: 10;
  position: relative;
}
.affiliate_link_box h2 {
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 8px;
}
.dash_black_box_panel {
  --gap: 15px;
  --max-column-count: 3;
  --min-column-width: 96px;
  --gap-count: calc(var(--max-column-count) - 1);
  --total-gap-width: calc(var(--gap-count) * var(--gap));
  --max-column-width: calc(
    (100% - var(--total-gap-width)) / var(--max-column-count)
  );
  grid-gap: var(--gap);
  display: grid;
  gap: var(--gap);
  grid-template-columns: repeat(
    auto-fill,
    minmax(max(var(--min-column-width), var(--max-column-width)), 1fr)
  );
  grid-template-rows: 1fr;
}
.dash_black_box {
  border: 1px solid rgba(157, 255, 250, 0.2);
  border-radius: 10px;
  backdrop-filter: blur(50px);
  background: linear-gradient(
    139.04deg,
    rgb(10, 15, 15) 3.47%,
    rgb(14, 45, 43) 134.161%
  );
  height: 100%;
  transition: 0.2s all;
  cursor: default;
  min-height: 146px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
.dash_black_box::before {
  content: "";
  background: url("./assets/images/box_logo_img.png") no-repeat right bottom;
  width: 110px;
  height: 104px;
  position: absolute;
  right: 0;
  bottom: 0;
}
.dash_black_box h4 {
  color: #9db9b7;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
}
.dash_black_box h5 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0;
}
.dash_black_box h5 span {
  color: #9f9f9f;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.affiliate_link_box {
  border-radius: 10px;
  backdrop-filter: blur(5px);
  background: radial-gradient(
    369.06% 199.18% at -11% -49%,
    rgb(98, 194, 189),
    rgb(0, 0, 0) 54%,
    rgb(46, 92, 90) 100%
  );
  padding: 15px 20px 15px 20px;
  border: 1px solid #62c2bd;
}
.packages_panel {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.packages_div .progress {
  width: 100%;
  height: 10px;
  background: #152726;
}
.packages_div .progress .progress-bar {
  background-color: #9dfffa;
}
.packages_div .progress .progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgb(255 255 255 / 50%) 25%,
    rgb(0 0 0 / 50%) 25%,
    transparent 50%,
    rgb(255 255 255 / 50%) 50%,
    rgb(255 255 255 / 50%) 75%,
    rgb(0 0 0 / 50%) 75%,
    rgb(0 0 0 / 0%)
  );
}
.packages_div {
  border: 1px solid rgba(98, 194, 189, 0.1);
  border-radius: 20px;
  backdrop-filter: blur(50px);
  background: rgba(46, 92, 90, 0.1);
  padding: 20px;
  position: relative;
  display: flex;
  gap: 10px;
}
.packages_div .packages_div_left,
.packages_div .packages_div_middle {
  width: 70%;
}
.packages_div_inner {
  border: 1px solid rgba(46, 92, 90, 0.2);
  border-radius: 10px;
  backdrop-filter: blur(50px);
  background: rgb(10, 15, 15);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.packages_div_inner h2 {
  color: #9db9b7;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}
.packages_div_left h3 {
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}
.packages_div_right > div {
  width: 80%;
}
.packages_div_right {
  flex-direction: row;
  width: 100%;
  gap: 20px;
}
.package_details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
}
.package_details h3 {
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
}
.package_details h4 {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  gap: 10px;
}
.package_details h4 span {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.progress_info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 10px 0;
}
.progress_info p {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0;
}
.progress_info p span {
  color: #9dfffa;
}
.packages_div .primary_btn {
  padding: 1px;
  box-shadow: none;
  font-size: 14px;
}
.packages_div .primary_btn span {
  text-align: center;
  padding: 10px 20px 10px 20px;
}
.capping_div {
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
}
.capping_div h3 {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0;
}
.capping_div h4 {
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  gap: 10px;
}
.capping_div h4 span {
  font-size: 14px;
  font-weight: 500;
}
/* Package 1 */
.packages_div_one .packages_div_inner {
  background: linear-gradient(
    180deg,
    #710003 -5%,
    #001465 54.63%,
    #001465 103.25%
  );
}
.packages_div_one .package_details h4 span,
.packages_div_one .package_details h3 {
  color: #d8a2a3;
}
.packages_div.active_package.packages_div_one {
  background: linear-gradient(
    180deg,
    rgba(255, 0, 8, 1) 0%,
    rgba(0, 86, 255, 1) 100%
  );
}
/* Package 2 */
.packages_div_two .packages_div_inner {
  background: linear-gradient(180deg, #060f00 0%, #000f13 100%);
}
.packages_div_two .primary_btn {
  background: linear-gradient(90deg, #70ff00 0%, #70ff00 100%);
}
.packages_div_two .primary_btn:hover span {
  color: #000;
}
.packages_div_two .package_details h4 span,
.packages_div_two .package_details h3,
.packages_div_two .capping_div h4 span {
  color: #94bc79;
}
.packages_div_two .capping_div {
  border: 1px solid rgba(148, 188, 121, 0.2);
}
.packages_div_two .capping_div h4 {
  color: #68f911;
}
.packages_div.active_package.packages_div_two {
  background: linear-gradient(180deg, #61ec04 0%, #70ff00 100%);
}
/* Package 3 */
.packages_div_three .packages_div_inner {
  background: linear-gradient(180deg, #160020 0%, #0e0700 96.44%);
}
.packages_div_three .primary_btn {
  background: linear-gradient(90deg, #ad00ff 0%, #ad00ff 100%);
}
.packages_div_three .primary_btn:hover span {
  color: #fff;
}
.packages_div_three .package_details h4 span,
.packages_div_three .package_details h3,
.packages_div_three .capping_div h4 span {
  color: #a978c0;
}
.packages_div_three .capping_div {
  border: 1px solid rgba(169, 120, 192, 0.2);
}
.packages_div_three .capping_div h4 {
  color: #ad00ff;
}
.packages_div.active_package.packages_div_three {
  background: linear-gradient(180deg, #ad00ff 0%, #ad00ff 100%);
}
/* Package 4 */
.packages_div_four .packages_div_inner {
  background: linear-gradient(180deg, #250d01 0%, #461801 96.44%);
}
.packages_div_four .primary_btn {
  background: linear-gradient(90deg, #ff5500 0%, #ff5500 100%);
}
.packages_div_four .primary_btn:hover span {
  color: #fff;
}
.packages_div_four .package_details h4 span,
.packages_div_four .package_details h3,
.packages_div_four .capping_div h4 span {
  color: #fd9a68;
}
.packages_div_four .capping_div {
  border: 1px solid rgba(169, 120, 192, 0.2);
}
.packages_div_four .capping_div h4 {
  color: #ff5500;
}
.packages_div.active_package.packages_div_four {
  background: linear-gradient(180deg, #ff5500 0%, #ff5500 100%);
}
/* Package 5 */
.packages_div_five .packages_div_inner {
  background: linear-gradient(180deg, #022e1f 0%, #010806 96.44%);
}
.packages_div_five .primary_btn {
  background: linear-gradient(90deg, #0fe89d 0%, #0fe89d 100%);
}
.packages_div_five .primary_btn:hover span {
  color: #000;
}
.packages_div_five .package_details h4 span,
.packages_div_five .package_details h3,
.packages_div_five .capping_div h4 span {
  color: #c6ffeb;
}
.packages_div_five .capping_div {
  border: 1px solid rgb(15 232 157 / 20%);
}
.packages_div_five .capping_div h4 {
  color: #0fe89d;
}
.packages_div.active_package.packages_div_five {
  background: linear-gradient(180deg, #0fe89d 0%, #0fe89d 100%);
}
/* Package 6 */
.packages_div_six .packages_div_inner {
  background: linear-gradient(180deg, #4c3200 0%, #221802 96.44%);
}
.packages_div_six .primary_btn {
  background: linear-gradient(90deg, #ffa800 0%, #ffa800 100%);
}
.packages_div_six .primary_btn:hover span {
  color: #000;
}
.packages_div_six .package_details h4 span,
.packages_div_six .package_details h3,
.packages_div_six .capping_div h4 span {
  color: #ffdd9b;
}
.packages_div_six .capping_div {
  border: 1px solid rgb(255 168 0 / 20%);
}
.packages_div_six .capping_div h4 {
  color: #ffa800;
}
.packages_div.active_package.packages_div_six {
  background: linear-gradient(180deg, #ffa800 0%, #ffa800 100%);
}
/* Package 7 */
.packages_div_seven .packages_div_inner {
  background: linear-gradient(180deg, #00146e 0%, #000b3f 96.44%);
}
.packages_div_seven .primary_btn {
  background: linear-gradient(90deg, #002eff 0%, #002eff 100%);
}
.packages_div_seven .primary_btn:hover span {
  color: #fff;
}
.packages_div_seven .package_details h4 span,
.packages_div_seven .package_details h3,
.packages_div_seven .capping_div h4 span {
  color: #a7b6ff;
}
.packages_div_seven .capping_div {
  border: 1px solid rgb(0 46 255 / 40%);
}
.packages_div_seven .capping_div h4 {
  color: #002eff;
}
.packages_div.active_package.packages_div_seven {
  background: linear-gradient(180deg, #002eff 0%, #002eff 100%);
}
/* Package 8 */
.packages_div_eight .packages_div_inner {
  background: linear-gradient(180deg, #740032 0%, #380017 96.44%);
}
.packages_div_eight .primary_btn {
  background: linear-gradient(90deg, #ff006b 0%, #ff006b 100%);
}
.packages_div_eight .primary_btn:hover span {
  color: #fff;
}
.packages_div_eight .package_details h4 span,
.packages_div_eight .package_details h3,
.packages_div_eight .capping_div h4 span {
  color: #ffa7cc;
}
.packages_div_eight .capping_div {
  border: 1px solid rgb(255 0 107 / 30%);
}
.packages_div_eight .capping_div h4 {
  color: #ff006b;
}
.packages_div.active_package.packages_div_eight {
  background: linear-gradient(180deg, #ff006b 0%, #ff006b 100%);
}
/* Package 9 */
.packages_div_nine .packages_div_inner {
  background: linear-gradient(180deg, #006379 0%, #00323d 96.44%);
}
.packages_div_nine .primary_btn {
  background: linear-gradient(90deg, #00d1ff 0%, #00d1ff 100%);
}
.packages_div_nine .primary_btn:hover span {
  color: #fff;
}
.packages_div_nine .package_details h4 span,
.packages_div_nine .package_details h3,
.packages_div_nine .capping_div h4 span {
  color: #bdf3ff;
}
.packages_div_nine .capping_div {
  border: 1px solid rgb(0 209 255 / 40%);
}
.packages_div_nine .capping_div h4 {
  color: #00d1ff;
}
.packages_div.active_package.packages_div_nine {
  background: linear-gradient(180deg, #00d1ff 0%, #00d1ff 100%);
}
/* Package 10 */
.packages_div_ten .packages_div_inner {
  background: linear-gradient(180deg, #769400 0%, #3a4900 96.44%);
}
.packages_div_ten .primary_btn {
  background: linear-gradient(90deg, #ccff00 0%, #ccff00 100%);
}
.packages_div_ten .primary_btn:hover span {
  color: #000;
}
.packages_div_ten .package_details h4 span,
.packages_div_ten .package_details h3,
.packages_div_ten .capping_div h4 span {
  color: #e7ff86;
}
.packages_div_ten .capping_div {
  border: 1px solid rgb(204 255 0 / 30%);
}
.packages_div_ten .capping_div h4 {
  color: #ccff00;
}
.packages_div.active_package.packages_div_ten {
  background: linear-gradient(180deg, #ccff00 0%, #ccff00 100%);
}
/*  */
.package_diasbled {
  pointer-events: none;
  user-select: none;
  background: transparent;
}
.package_diasbled .packages_div_inner {
  background: rgba(37, 37, 37, 0.2);
}
.packages_div.package_diasbled .primary_btn {
  color: #272727 !important;
  background: #000;
}
.packages_div.package_diasbled .capping_div {
  border: 1px solid #3d3d3d;
}
/* Tree Structure */
.tree {
  width: 100%;
  height: 100%;
}
.tree ul {
  margin: 0;
  padding: 0;
  padding-top: 20px;
  position: relative;
  transition: all 0.5s;
  display: flex;
  justify-content: center;
}
.tree li {
  /* float: left; */
  text-align: center;
  list-style-type: none;
  position: relative;
  padding: 30px 20px 0 20px;
  transition: all 0.5s;
}
.tree li::before,
.tree li::after {
  content: "";
  position: absolute;
  top: 0;
  right: 50%;
  border-top: 2px solid #1e1e1e;
  width: 50%;
  height: 30px;
}
.tree li::after {
  right: auto;
  left: 50%;
  border-left: 2px solid #1e1e1e;
}
.tree li:only-child::after,
.tree li:only-child::before {
  display: none;
}
.tree li:only-child {
  padding-top: 0;
}
.tree li:first-child::before,
.tree li:last-child::after {
  border: 0 none;
}
.tree li:last-child::before {
  border-right: 2px solid #1e1e1e;
  border-radius: 0 2px 0 0;
}
.tree li:first-child::after {
  border-radius: 2px 0 0 0;
}
.tree ul ul::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  border-left: 2px solid #1e1e1e;
  width: 0;
  height: 20px;
}
.tree li span {
  border: 2px solid #1e1e1e;
  padding: 5px 10px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.5s;
  width: 64px;
  height: 64px;
  margin: 0 auto;
  z-index: 10;
  position: relative;
}
/* .tree li span:hover,
.tree li span:hover + ul li span {
  background: #c8e4f8;
  color: #000;
  border: 1px solid #1E1E1E;
} */
.tree li span:hover + ul li::after,
.tree li span:hover + ul li::before,
.tree li span:hover + ul::before,
.tree li span:hover + ul ul::before {
  border-color: #1e1e1e;
}
.tree li span.tree_package_one {
  border: 2px solid #0056ff;
}
.tree li span.tree_package_two {
  border: 2px solid #70ff00;
}
.tree li span.tree_package_three {
  border: 2px solid #ad00ff;
}
.tree li span.tree_package_four {
  border: 2px solid #ff5500;
}
.tree li span.tree_package_five {
  border: 2px solid #0fe89d;
}
.tree li span.tree_package_six {
  border: 2px solid #ffa800;
}
.tree li span.tree_package_seven {
  border: 2px solid #0500ff;
}
.tree li span.tree_package_eight {
  border: 2px solid #ff006b;
}
.tree li span.tree_package_nine {
  border: 2px solid #00d1ff;
}
.tree li span.tree_package_ten {
  border: 2px solid #ccff00;
}
.custom_tooltip {
  z-index: 100;
}
table.table.primary_table {
  border: 1px solid transparent;
  margin-bottom: 0;
}
.primary_table thead tr {
  background: linear-gradient(139.04deg, rgb(10, 15, 15) 3.47%, rgb(14, 45, 43) 134.161%);
}
.primary_table thead th {
  color: #9db9b7;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.8px;
  background: transparent;
  border: 1px solid transparent;
  border-left: 0;
  border-right: 0;
  padding: 12px 30px;
  white-space: nowrap;
}
.primary_table tbody td {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.8px;
  border-bottom: 1px solid transparent;
  background: rgba(37, 37, 37, 0.2);
  padding: 12px 30px;
}
.primary_table tbody tr {
  transition: 0.2s all;
}
.primary_table tbody tr:hover {
  background: rgb(0 0 0 / 40%);
}
.flex_maintitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  margin-bottom: 30px;
}
.flex_maintitle .inner_head_title {
  margin-bottom: 0;
}
.dash_black_box_grid {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(5, minmax(0, 1fr));
}
.dash_height_row_inner.dash_height_row::before {
  top: 200px;
  height: calc(100% - 180px);
}
.packages_panel_mobile {
  display: none;
}
.primary_inp {
  background: transparent !important;
  border: 1px solid rgba(157, 255, 250, 0.2) !important;
  box-shadow: none !important;
  color: #ccdbda !important;
  font-size: 16px;
  min-height: 50px;
}
.primary_inp::placeholder {
  color: rgba(157, 185, 183, 0.5);
}
.features_div h2 {
  color: #9dfffa;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  margin: 30px 0 15px;
}
.features_div {
  max-width: 80%;
  margin: 0 auto;
  position: relative;
}
.features_div_line::before {
  content: "";
  background: #152726;
  width: 0.5px;
  height: 100%;
  position: absolute;
  right: -50px;
}
.features_div_line::after {
  content: "";
  background: #152726;
  width: 5px;
  height: 30px;
  position: absolute;
  right: -52px;
  top: 42%;
  border-radius: 6px;
}
.default_id {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
}
.default_id p {
  font-size: 14px;
  margin-bottom: 0;
  color: #9db9b7;
}
.default_id i {
  color: #3eb2af;
}
.default_id p span {
  white-space: normal;
  word-break: break-word;
}

/* Media Queries */
@media  screen and (max-height: 699px) {
  .page_header {
    padding: 120px 0;
  }
}
@media screen and (max-width: 1399px) {
  .activity_slider_single {
    padding: 10px;
    margin: 6px;
  }
  .activity_time p {
    font-size: 14px;
  }
  .dash_height_row::before {
    top: 375px;
    height: calc(100% - 350px);
  }
}
@media screen and (max-width: 1199px) {
  .activity_slider_single {
    margin: 10px;
  }
  .custom_slider .slick-prev {
    left: 42%;
  }
  .custom_slider .slick-next {
    right: 42%;
  }
  .main_title {
    font-size: 32px;
    margin-bottom: 30px;
  }
  .community_section_inner .nav-fill .nav-item .nav-link {
    font-size: 30px;
  }
  .community_section_inner .nav-fill .nav-item .nav-link svg {
    width: 50px;
    height: 50px;
  }
  .user_earnings_panel {
    display: block;
  }
  .packages_panel {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .tree li {
    padding: 30px 10px 0 10px;
  }
  .tree li span {
    width: 56px;
    height: 56px;
  }
  .dash_black_box_grid {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .dash_black_box_panel {
    --max-column-count: 3;
  }
  .packages_div {
    flex-direction: column;
    margin: 10px;
  }
  .packages_div .packages_div_left,
  .packages_div .packages_div_middle {
    width: 100%;
  }
  .packages_panel_desktop {
    display: none;
  }
  .packages_panel_mobile {
    display: block;
  }
  .packages_div_right > div {
    width: 100%;
  }
  .packages_div_right {
    flex-direction: column;
  }
  .inner_wrapper::after {
    display: none;
  }
  .features_div_line::before {
    right: -40px;
  }
  .features_div_line::after {
    right: -42px;
  }
  .banner_content img {
    margin: -180px 0 -50px;
  }
}
@media screen and (max-width: 991px) {
  .main_navbar .offcanvas {
    background: linear-gradient(
      180deg,
      rgb(0, 0, 0) 50%,
      rgb(98, 194, 189) 375.495%
    );
  }
  .offcanvas-header .btn-close {
    filter: invert(1);
    box-shadow: none !important;
  }
  .activity_details p {
    font-size: 14px;
  }
  .activity_details h5 {
    font-size: 18px;
  }
  .activity_details img {
    width: 50px;
  }
  .custom_slider .slick-next {
    right: 38%;
  }
  .custom_slider .slick-prev {
    left: 38%;
  }
  .community_section_inner .nav-fill .nav-item .nav-link {
    font-size: 20px;
  }
  .community_section_inner .nav-fill .nav-item .nav-link svg {
    width: 32px;
    height: 32px;
  }
  .community_section_inner .nav-pills {
    position: relative;
    bottom: unset;
  }
  .community_section_inner .tab-content {
    max-width: 100%;
    margin: 30px 0;
  }
  .community_section::after {
    background-size: 80%;
    background-repeat: no-repeat;
    top: 0;
  }
  .stats_panel_wrapper .container {
    max-width: 98%;
  }
  .stats_panel {
    overflow-x: auto;
    padding-bottom: 10px;
  }
  .stats_panel div {
    gap: 15px;
  }
  .navbar-toggler {
    box-shadow: none !important;
  }
  .activity_section .small_main_title {
    margin-top: 0;
  }
  .benefits_list {
    margin-bottom: 30px;
  }
  .dash_top_box [class*="col-"] {
    margin-bottom: 24px;
  }
  .navbar_right {
    flex-direction: column;
    width: 100%;
  }
  .navbar_right .primary_btn,
  .navbar_right .dropdown,
  .main_navbar .primary_btn span {
    width: 100% !important;
  }
  .dropdown-menu {
    background: rgb(37 37 37);
    mix-blend-mode: unset;
  }
  .dashboard_accordian {
    padding-left: 15px;
    margin-top: 30px;
  }
  .dashboard_accordian .accordion-item {
    margin-bottom: 50px;
  }
  .dash_black_box_grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .dash_black_box_panel {
    --max-column-count: 2;
  }
  .features_div_line::before {
    width: 100%;
    height: 0.5px;
    right: 0;
    bottom: -25px;
  }
  .features_div_line::after {
    width: 30px;
    height: 5px;
    right: 0;
    left: 0;
    margin: 0 auto;
    bottom: -28px;
    top: unset;
  }
  .features_div {
    max-width: 100%;
    margin-bottom: 50px;
  }
  .features_div h2 {
    margin: 15px 0 10px;
  }
  .about_section {
    text-align: center;
  }
  .benefits_section img {
    margin-bottom: 30px;
  }
  .footer_panel_top,
  .footer_panel_bottom {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 15px;
  }
  .footer_panel_bottom {
    gap: 3px;
  }
  .main_navbar {
    backdrop-filter: none;
    background: linear-gradient(
      90deg,
      rgb(11 22 21) 1%,
      rgb(0 0 0) 50%,
      rgb(10 20 20) 100%
    );
  }
  .banner_content h5 {
    font-size: 36px;
    line-height: 36px;
  }
  .banner_content h2 {
    font-size: 42px;
    line-height: 60px;
  }
  .banner_content img {
    margin: -140px 0 -50px;
    width: 500px;
  }
}
@media screen and (max-width: 767px) {
  .footer_about p {
    max-width: 100%;
    margin-bottom: 15px;
  }
  .community_section_inner .nav-pills {
    justify-content: flex-start;
    gap: 15px;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: 15px;
  }
  .community_section_inner .nav-fill .nav-item .nav-link {
    font-size: 18px;
    white-space: nowrap;
  }
  .activity_slider_single {
    padding: 15px;
  }
  .custom_slider .slick-next {
    right: 35%;
  }
  .custom_slider .slick-prev {
    left: 35%;
  }
  .page_header::before {
    width: 100%;
  }
  .para,
  .benefits_list li {
    font-size: 16px;
    line-height: 28px;
  }
  .custom_accordian .accordion-button {
    font-size: 18px;
    letter-spacing: -0.1px;
  }
  .benefits_list li {
    margin-top: 20px;
  }
  .packages_panel {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .tree {
    overflow-x: auto;
  }
  .tree ul {
    justify-content: flex-start;
    margin-bottom: 5px;
  }
  .dashboard_accordian .accordion-button,
  .user_earnings_info h3 {
    font-size: 20px;
  }
  .inner_head_title {
    font-size: 30px;
    margin-bottom: 50px;
  }
  .user_info h4 {
    font-size: 20px;
    margin-bottom: 5px;
  }
  .user_info h5 {
    font-size: 16px;
  }
  .dash_black_box_grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .flex_maintitle {
    gap: 15px;
    margin-bottom: 50px;
  }
  .dash_black_box_panel {
    --max-column-count: 1;
  }
  .banner_content h5 {
    font-size: 24px;
    line-height: 20px;
  }
  .banner_content h2 {
    font-size: 32px;
    line-height: 42px;
  }
}
@media screen and (max-width: 575px) {
  .small_main_title {
    font-size: 24px;
  }
  .dash_black_box_grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .flex_maintitle {
    flex-direction: column;
  }
  .page_header {
    padding: 30px 0;
  }
  .banner_content {   
    min-height: 85vh;
  }
  .banner_content img {
    margin: -80px 0 -30px;
  }
  .banner_content h5 {
    font-size: 20px;
  }
  .banner_content h2 {
    font-size: 24px;
    line-height: 32px;
  }
  .main_title {
    font-size: 26px;
  }
  .primary_table thead th {
    font-size: 16px;
  }
}
@media screen and (max-width: 440px) {
  .activity_slider_single {
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
  }
  .activity_details {
    width: 100%;
    justify-content: center;
  }
  .activity_time {
    width: 100%;
    justify-content: center;
    margin-top: 5px;
  }
  .custom_slider .slick-next {
    right: 25%;
  }
  .custom_slider .slick-prev {
    left: 25%;
  }
  img.brand_logo {
    width: 210px;
  }
}
.loadBefore::before {
  content: "";
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgb(10 10 10 / 75%);
  z-index: 1500;
  overflow: hidden;
}
button:disabled {
  opacity: 0.4;
}
